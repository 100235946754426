/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)


require('../css/bootstrap-datepicker.css');
require('../css/global.scss');
//require('../css/jquery.dataTables.css');

require('../css/dataTables.bootstrap4.css');
require('../css/sb-admin-2.css');
require('../css/app.css');
require('../css/select2.min.css');


require('../css/jquery.timepicker.css');
//require('../css/jquery.bootstrap.year.calendar.css');


const $ = require('jquery');
global.$ = $;
window.jQuery = $;


require('jquery.easing');


require('bootstrap');
require('datatables.net-bs4');
require('select2');
require('./jquery.timepicker.js');
require('./jquery.repeater.min.js');
//require('./jquery.bootstrap.year.calendar.js');
require('./bootstrap-datepicker.min.js');
